import * as React from "react";
import { useForm } from "react-hook-form";

export interface FormInputs {
  send_to_patient: boolean;
  send_to_clinician: boolean;
  clinician_name: string;
  clinician_email: string;
}

export const ImageRequestForm: React.FC = () => {
  const [showClinicianDetails, setShowClinicianDetails] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { watch, register, setValue, getValues } = useForm<FormInputs>();

  const onSubmit = (event) => {
    const checkboxes = getValues(["send_to_patient", "send_to_clinician"]);

    if (checkboxes.some((checkbox) => checkbox)) {
      return;
    }

    event.preventDefault();
    setErrorMessage("Please select at least one option.");
  };

  React.useEffect(() => {
    const formSubscription = watch(({ send_to_clinician }, { name }) => {
      setErrorMessage("");
      if (name === "send_to_clinician") {
        setShowClinicianDetails(!!send_to_clinician);
      }
    });
    return () => formSubscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {!!errorMessage && (
        <div className="mb-8 rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{errorMessage}</p>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 border-b border-t border-gray-200 pb-8 pt-4">
        <div className="mt-4 flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="send_to_patient"
              type="checkbox"
              className="form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              {...register("send_to_patient")}
            />
          </div>
          <div className="ml-3 ">
            <label
              htmlFor="send_to_patient"
              className="font-medium text-gray-700"
            >
              Send images to myself
            </label>
            <p className="text-gray-500">
              The email associated with the order.
            </p>
          </div>
        </div>
        <div className="mt-4 flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="send_to_clinician"
              type="checkbox"
              className="form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              {...register("send_to_clinician")}
            />
          </div>
          <div className="ml-3 ">
            <label
              htmlFor="send_to_clinician"
              className="font-medium text-gray-700"
            >
              Send images to a clinician
            </label>
          </div>
        </div>
      </div>
      <div className="space-y-8 divide-y divide-gray-200">
        {showClinicianDetails && (
          <div className="">
            <div className="pt-8 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
              <label
                htmlFor="clinician_name"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Clinician name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  required={true}
                  type="text"
                  id="clinician_name"
                  className="form-input block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  {...register("clinician_name")}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <label
                htmlFor="clinician_email"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Clinician email
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  required={true}
                  type="email"
                  id="clinician_email"
                  className="form-input block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  {...register("clinician_email")}
                />
              </div>
            </div>
          </div>
        )}

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              onClick={onSubmit}
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

import { Button, formatCurrency } from "@scandotcom/react";
import {
  Map,
  MapMarker,
  useMap,
} from "@scandotcom/react/dist/esm/components/Map";
import { IconAdjustmentsHorizontal, IconMapPin } from "@tabler/icons-react";
import classNames from "classnames";
import React, { useState } from "react";
import { NavigationControl } from "react-map-gl";
import { FilterModal } from "./FilterModal";
import { LocationCard } from "./LocationCard";
import { MapPopup } from "./MapPopup";
import { MobileFilterToggle } from "./MobileFilterToggle";
import type { ScanningLocation } from "./types";

interface Props {
  locations?: ScanningLocation[];
  patientLoginToken: string;
  initialInputs?: Record<string, string>;
  hasPrices?: boolean;
}

export function SearchProviders({
  locations = [],
  patientLoginToken,
  initialInputs = {},
  hasPrices,
}: Props) {
  const bookNowAction = `/patients/${patientLoginToken}/scanning_center`;
  const { mapRef, fitToBounds, selectedLocation, setLocation } =
    useMap(locations);
  const [showFilters, setShowFilters] = useState(false);
  const onClose = () => setShowFilters(false);
  const onOpen = () => setShowFilters(true);

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        onClose={onClose}
        inputs={initialInputs}
        hasPrices={hasPrices}
      />

      <div className="relative grid h-full gap-x-9 sm:container max-xl:auto-rows-min sm:gap-y-8 sm:py-10 xl:grid-cols-[5fr,4fr]">
        <div className="space-y-4 max-sm:hidden">
          <h1 className="font-maison-extended text-3xl font-bold text-cypress-800">
            Choose a scanning centre
          </h1>

          <p className="text-cypress-900">
            Enter your location to compare scanning centres near you, and choose
            the option that suits you best. You can also use the filters to
            customise your search.
          </p>

          <div className="flex flex-col gap-8">
            <form className="flex items-center gap-2.5 rounded-xl border border-neutral-300 bg-white p-2.5 max-sm:hidden">
              <IconMapPin width={28} className="text-neutral-700" />

              <label className="grow text-base font-medium">
                <div className="text-neutral-500">Location</div>
                <input
                  type="text"
                  name="area"
                  className="w-full border-none p-0 focus:ring-0"
                  defaultValue={initialInputs.area}
                />
              </label>
              <Button type="submit" kind="secondary">
                Search
              </Button>
            </form>

            <Button
              kind="white"
              type="button"
              onClick={onOpen}
              className="ml-auto flex items-center justify-center gap-2 max-xl:hidden"
            >
              <IconAdjustmentsHorizontal width={20} />
              Filters
            </Button>
          </div>
        </div>

        <Map
          ref={mapRef}
          onLoad={fitToBounds}
          mapboxAccessToken={window.AppData.MapboxAccessToken}
          scrollZoom={false}
          showNavControl={false}
          attributionControl={false}
          className={mapStyles}
        >
          <MobileFilterToggle area={initialInputs.area} onClick={onOpen} />

          <NavigationControl position="bottom-right" showCompass={false} />
          {locations.map((item) => (
            <MapMarker
              key={item.id}
              longitude={item.longitude}
              latitude={item.latitude}
              onClick={() => setLocation(item, true)}
              price={
                item.price
                  ? formatCurrency(item.price / 100, { currency: "GBP" })
                  : undefined
              }
            />
          ))}

          {selectedLocation && (
            <MapPopup
              location={selectedLocation}
              onClose={() => setLocation(null, true)}
              formAction={bookNowAction}
            />
          )}
        </Map>

        <Button
          kind="white"
          type="button"
          onClick={onOpen}
          className="flex items-center justify-center gap-2 max-sm:hidden xl:hidden"
        >
          <IconAdjustmentsHorizontal width={20} />
          Filters
        </Button>

        <div className="border-neutral-300 max-sm:z-20 max-sm:-mt-4 max-sm:rounded-3xl max-sm:border max-sm:bg-white max-sm:pt-10">
          <div className="container space-y-2 sm:hidden">
            <h2 className="font-maison-extended text-xl font-bold text-cypress-800">
              Choose a scanning centre
            </h2>

            <p className="text-sm text-cypress-900">
              Enter your location to compare scanning centres near you, and
              choose the option that suits you best. You can also use the
              filters to customise your search.
            </p>
          </div>

          <ul className="sm:space-y-4">
            {locations.map((location) => (
              <LocationCard
                key={location.id}
                location={location}
                action={bookNowAction}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

const mapStyles = classNames(
  "relative isolate border border-neutral-300 h-96 transition-[height] delay-300",
  "xl:top-4 xl:sticky lg:min-h-[600px] xl:h-[calc(100vh-120px)] sm:rounded-2xl max-sm:order-first xl:row-span-3",
  "max-sm:[&_.mapboxgl-ctrl-bottom-left]:!mb-4 max-sm:[&_.mapboxgl-ctrl-bottom-right]:!mb-4"
);

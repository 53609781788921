import "@hotwired/turbo-rails";
import { SearchProviders } from "components/V2SearchProviders/SearchProviders";
import React from "react";
import { define } from "remount";
import { BugsnagErrorBoundary } from "services/BugsnagErrorBoundary";
import { identifyUser } from "services/analytics";
import { ImageRequestForm } from "../components/ImageRequestForm";
import { MessageBox } from "../components/MessageBox";
import "../form";
import { MapLocation } from "components/MapLocation";

identifyUser();

const components: Record<string, React.ComponentType<any>> = {
  "v2-search-imaging-providers": SearchProviders,
  "map-location": MapLocation,
  "image-request-form": ImageRequestForm,
  "message-box": MessageBox,
};

define(Object.keys(components).reduce((definitions, key) => {
  const Component = components[key];
  definitions[key] = (props: any) => (
    <BugsnagErrorBoundary>
      <Component {...props} />
    </BugsnagErrorBoundary>
  );
  return definitions;
}, {}));

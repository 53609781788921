import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import notifyError from "services/notifyError";

Bugsnag.start({
  apiKey: window.AppData.BugsnagApiKey,
  appVersion: window.AppData.AppVersion || undefined,
  releaseStage: window.AppData.ReleaseStage,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "sandbox", "staging", "review_app"],
  metadata: { referralReference: window.AppData.ReferralReference },
});

const reactPlugin = Bugsnag.getPlugin("react");

if (!reactPlugin) {
  notifyError("BugsnagErrorBoundary", {
    description: "react plugin not found",
  });
}

export const BugsnagErrorBoundary =
  reactPlugin?.createErrorBoundary(React) || React.Fragment;

import { IconAdjustmentsHorizontal, IconSearch } from "@tabler/icons-react";
import React from "react";

interface Props {
  area: string;
  onClick: () => void;
}

export function MobileFilterToggle({ area, onClick }: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="absolute inset-2 bottom-auto z-20 flex items-center gap-4 rounded-full border border-neutral-300 bg-white p-4 pr-5 text-base font-medium text-neutral-950 sm:hidden"
    >
      <IconSearch width={24} />
      <div className="line-clamp-1 font-medium">{area}</div>
      <IconAdjustmentsHorizontal
        size={32}
        stroke={1.5}
        className="ml-auto text-neutral-700"
      />
    </button>
  );
}

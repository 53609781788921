import React from "react";

export function Rating({ rating = 5, min = 1, max = 5 }) {
  const clampedRating = Math.min(Math.max(Math.round(rating), min), max);

  const label = `Rating: ${rating} out of ${max} stars`;
  const stars = Array.from(Array(clampedRating).keys());

  return (
    <div className="flex items-center" role="img" aria-label={label}>
      {stars.map((index) => (
        <img
          key={index}
          src="/images/rating.png"
          alt=""
          aria-hidden
          className="h-4 w-4"
        />
      ))}
    </div>
  );
}

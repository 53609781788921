import { formatCurrency } from "@scandotcom/react";
import { IconMapPin, IconPointFilled } from "@tabler/icons-react";
import React from "react";
import { Rating } from "./Rating";
import { ScanningLocation } from "./types";

interface Props {
  location: ScanningLocation;
  action: string;
}

export function LocationCard({ location, action }: Props) {
  const address = [location.address, location.county, location.postcode]
    .filter(Boolean)
    .join(", ");
  return (
    <li className="flex gap-4 border border-neutral-200 p-5 max-sm:flex-col max-sm:first:border-t-0 sm:rounded-2xl">
      <div className="grow text-sm">
        <h2
          title={location.address}
          className="line-clamp-1 font-maison-extended text-lg font-bold text-neutral-900"
        >
          {location.name}
        </h2>

        {location.rating > 0 && (
          <div className="flex items-center gap-1">
            <Rating rating={location.rating} />
            <span className="font-medium text-neutral-950">
              {location.rating}
            </span>
            {location.reviews > 0 && (
              <span className="text-neutral-500 underline">
                ({location.reviews} reviews)
              </span>
            )}
          </div>
        )}

        <div className="my-2 flex items-center">
          <IconMapPin width={20} className="mr-1 text-neutral-700" />
          <span className="font-medium text-neutral-950">
            {Math.round(location.distance)}mi
          </span>
          <IconPointFilled className="mx-2 text-neutral-950" width={4} />
          <span title={address} className="line-clamp-1 text-neutral-600">
            {address}
          </span>
        </div>

        <div className="flex flex-wrap gap-1 text-sm">
          {Boolean(location.min_age) && (
            <div className="whitespace-nowrap rounded-lg bg-neutral-100 px-2 py-1 text-neutral-900">
              Min age: {location.min_age}+
            </div>
          )}

          {Boolean(location.open_scanner) && (
            <div className="whitespace-nowrap rounded-lg bg-neutral-100 px-2 py-1 text-neutral-900">
              Open scanner
            </div>
          )}

          {Boolean(location.three_t) && (
            <div className="whitespace-nowrap rounded-lg bg-neutral-100 px-2 py-1 text-neutral-900">
              3T scanner
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-wrap items-end justify-between sm:flex-col">
        <div className="sm:text-end">
          {location.price && (
            <div className="font-maison-extended text-xl font-bold text-neutral-900">
              {formatCurrency(location.price / 100, { currency: "GBP" })}
            </div>
          )}
        </div>

        <form method="patch" action={action}>
          <button
            className="btn btn-primary whitespace-nowrap"
            name="imaging_provider_id"
            data-test="list_select_center"
            value={location.id}
          >
            Book now
          </button>
        </form>
      </div>
    </li>
  );
}

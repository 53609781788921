document.addEventListener("turbo:load", () => {
  const conditionalRadios = document.querySelectorAll(".js-conditional-radio");

  conditionalRadios.forEach((wrapper) => {
    const radio = wrapper.querySelectorAll(
      ".js-conditional-radio input[type=radio]"
    );
    const target = wrapper.querySelector(
      ".js-conditional-radio .js-conditional-radio-target"
    );

    radio.forEach((input) => {
      if (input.checked === true && input.value === "true") {
        target.style.display = "block";
      }
      input.addEventListener("change", (e) => {
        if (e.target.value === "true") {
          target.style.display = "block";
        } else {
          target.style.display = "none";
        }
      });
    });
  });

  const backButtons = document.querySelectorAll(".js-back-button");

  backButtons.forEach((button) => {
    button.addEventListener("click", () => {
      window.history.back();
    });
  });
});

import { Checkbox, Modal, RadioInput, Switch } from "@scandotcom/react";
import { IconMapPin } from "@tabler/icons-react";
import React from "react";

interface Props
  extends Omit<React.ComponentProps<typeof Modal>, "children" | "className"> {
  inputs: Record<string, string>;
  hasPrices?: boolean;
}

const descriptionStyle =
  "max-w-64 text-sm font-normal text-neutral-600 lg:max-w-xs";
const switchStyles =
  "flex-row-reverse justify-between text-neutral-950 text-base font-medium mb-2";

const distanceOptions = [
  { value: "distance-asc", label: "Distance low to high" },
  { value: "distance-desc", label: "Distance high to low" },
];

const priceOptions = [
  { value: "price-asc", label: "Price low to high" },
  { value: "price-desc", label: "Price high to low" },
];

export function FilterModal({
  onClose,
  transition = "up",
  inputs,
  hasPrices,
  ...props
}: Props) {
  const orderOptions = hasPrices
    ? [...priceOptions, ...distanceOptions]
    : distanceOptions;

  return (
    <Modal
      onClose={onClose}
      transition={transition}
      className="relative w-screen sm:max-w-sm"
      {...props}
    >
      <Modal.Header onClose={onClose}>Filters</Modal.Header>

      <form id="filters-form" className="pt-6">
        <SearchInput initialArea={inputs.area} />

        <fieldset className="space-y-4 pb-5 font-medium text-neutral-700">
          <legend className="font-maison-extended text-lg font-bold text-black">
            Sort by
          </legend>

          {orderOptions.map(({ value, label }) => (
            <RadioInput
              key={value}
              label={label}
              value={value}
              defaultChecked={inputs.order === value}
              name="order"
            />
          ))}
        </fieldset>

        <div className="border-t border-neutral-200 py-5">
          <Switch
            label="Online appointment selection"
            name="online_appointments"
            className={switchStyles}
            defaultChecked={Boolean(inputs["online_appointments"])}
          />
          <p className={descriptionStyle}>
            Show only centres with online appointment availability to choose a
            preferred date and time when booking.
          </p>
        </div>

        <fieldset className="space-y-4 border-t border-neutral-200 py-5">
          <legend className="float-left mb-4 block text-base font-medium text-neutral-950">
            Facilities
          </legend>

          <Checkbox
            label="Open scanner"
            name="open_scanner"
            className="clear-left"
            defaultChecked={Boolean(inputs["open_scanner"])}
          />
          <Checkbox
            label="3T MRI scanner"
            name="three_t"
            defaultChecked={Boolean(inputs["three_t"])}
          />
          <Checkbox
            label="Accessible"
            name="accessible"
            defaultChecked={Boolean(inputs["accessible"])}
          />
        </fieldset>
      </form>

      <div className="sticky -bottom-6 -m-6 mt-6 flex justify-between border-neutral-200 px-6 py-5 max-sm:flex-col sm:border-t sm:bg-white">
        <button type="button" onClick={onClose} className="btn max-sm:hidden">
          Cancel
        </button>
        <button form="filters-form" className="btn btn-primary">
          Update results
        </button>
      </div>
    </Modal>
  );
}

function SearchInput({ initialArea = "" }) {
  return (
    <div className="mb-6 flex items-center gap-2.5 rounded-xl border border-gray-300 bg-white px-3 py-2 ring-pink-300 ring-offset-2 focus-within:ring-2 sm:hidden">
      <IconMapPin width={28} className="text-neutral-700" aria-hidden />
      <label className="block grow font-medium text-neutral-950">
        <div className="text-sm text-neutral-500">Location</div>
        <input
          type="text"
          name="area"
          defaultValue={initialArea}
          className="w-full outline-none"
        />
      </label>
    </div>
  );
}

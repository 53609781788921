import classNames from "classnames";
import * as React from "react";

interface MessageBoxProps {
  className?: string;
  message: string;
}

export const MessageBox: React.FC<MessageBoxProps> = ({
  className,
  message,
}) => {
  const [entered, setEntered] = React.useState(false);

  return (
    <div
      onMouseEnter={() => setEntered(true)}
      className={classNames(
        "relative group-hover:pointer-events-auto group-hover:opacity-100",
        {
          "pointer-events-none opacity-0": entered,
          "!opacity-100": !entered,
        }
      )}
    >
      <div
        className={classNames(
          "absolute w-72 rounded border border-gray-200 bg-white px-4 py-3 text-sm",
          className
        )}
      >
        <span className="absolute -top-[1px] left-2 h-4 w-4 -translate-y-1/2 rotate-45 border-l border-t border-inherit bg-inherit"></span>
        <p>{message}</p>
      </div>
    </div>
  );
};

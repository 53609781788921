import React from "react";
import {
  Map,
  MapMarker,
  useMap,
} from "@scandotcom/react/dist/cjs/components/Map";

interface Props {
  latitude: number;
  longitude: number;
}

export function MapLocation({ latitude, longitude }: Props) {
  const { mapRef, fitToBounds } = useMap([{ latitude, longitude }]);

  return (
    <Map
      ref={mapRef}
      onLoad={fitToBounds}
      mapboxAccessToken={window.AppData.MapboxAccessToken}
      className="h-72 rounded-xl"
    >
      <MapMarker latitude={latitude} longitude={longitude} />
    </Map>
  );
}
